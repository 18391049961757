import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import CustomSelect from '../../../components/form/CustomSelect';
import { Rules } from '../../../helpers/RHFRules';
import CustomInput from '../../../components/form/CustomInput';

const CertificationServices = (props) => {
  const {
    productType,
    listDeviceTypeContracts,
    listValidityTypeContracts,
    transformedListMaxValidityAmountContracts,
    parameterMaxReassignmentQuota,
    listCredentialDeliveryContracts,
    setValueProducts,
    getValuesProducts,
    resetProduct,
    clearErrorsProducts,
    controlProducts,
    errorsProducts,
    handleSubmitProducts,
    theme,
    classes,
    deviceTypeOptions,
    setDeviceTypeOptions,
    lastValidValueQuantityProducts,
    setLastValidValueQuantityProducts,
    isEditing,
    setIsEditing,
    servicesToAdd,
    setServicesToAdd,
    selectedIndex,
    setSelectedIndex,
    credentialsOptions,
    setCredentialsOptions,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const setProductType = (event) => {
    setValueProducts('deviceType', '');
    setValueProducts('credentialsDeliveryType', '');
    const { value } = event.target;
    if (event.target.name === 'productService') {
      setValueProducts('productService', value);
    }
    if (productType.length > 0) {
      const deviceTypeId = productType.find(
        (pack) => pack.value === getValuesProducts('productService'),
      )?.deviceTypeId;
      const deviceTypeIdsArray = deviceTypeId
        ? deviceTypeId.split(',').map(Number)
        : [];
      if (deviceTypeIdsArray.length > 0) {
        setDeviceTypeOptions(
          listDeviceTypeContracts.filter((option) => deviceTypeIdsArray.includes(option.value)),
        );
      }
    }
    return value;
  };

  const setValidityType = (event) => {
    const { value } = event.target;
    if (event.target.name === 'validityService') {
      setValueProducts('validityService', value);
      setValueProducts('validityAmount', '');
    }
    return value;
  };

  const setDeviceType = (event) => {
    setValueProducts('credentialsDeliveryType', '');
    const { value } = event.target;
    if (event.target.name === 'deviceType') {
      setValueProducts('deviceType', value);
    }
    if (value === 1) {
      setCredentialsOptions(listCredentialDeliveryContracts.filter((option) => option.value === 1));
    } else if (value === 2) {
      setCredentialsOptions(listCredentialDeliveryContracts);
    }
    return value;
  };

  const setCredentialsType = (event) => {
    const { value } = event.target;
    if (event.target.name === 'credentialsDeliveryType') {
      setValueProducts('credentialsDeliveryType', value);
    }
    return value;
  };

  const handleAgregarProducto = (data) => {
    if (isEditing) {
      const codeDevice = listDeviceTypeContracts.find(
        (validity) => validity.value === data.deviceType,
      )?.codeDevice;
      const identifierCode = `${data.productService}_${codeDevice}_${data.validityAmount}${data.validityService}`;

      const exists = servicesToAdd.some((serv, index) => {
        const codeDeviceService = listDeviceTypeContracts.find(
          (validity) => validity.value === serv.deviceType,
        )?.codeDevice;
        return index !== selectedIndex && identifierCode === `${serv.productService}_${codeDeviceService}_${serv.validityAmount}${serv.validityService}`;
      });

      if (exists) {
        enqueueSnackbar('Ya existe el producto en la lista.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        return;
      }
      setServicesToAdd((prevServices) => prevServices.map(
        (service, index) => (index === selectedIndex ? { ...service, ...data } : service),
      ));
    } else {
      const codeDevice = listDeviceTypeContracts.find(
        (validity) => validity.value === data.deviceType,
      )?.codeDevice;
      const identifierCode = `${data.productService}_${codeDevice}_${data.validityAmount}${data.validityService}`;

      const exists = servicesToAdd.some((serv) => {
        const codeDeviceService = listDeviceTypeContracts.find(
          (validity) => validity.value === serv.deviceType,
        )?.codeDevice;
        return identifierCode === `${serv.productService}_${codeDeviceService}_${serv.validityAmount}${serv.validityService}`;
      });

      if (exists) {
        enqueueSnackbar('Ya existe el producto en la lista.', {
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
        return;
      }

      setServicesToAdd((prevServices) => [...prevServices, data]);
    }
    setValueProducts('productService', '');
    setValueProducts('quantityToAddService', '');
    setValueProducts('validityService', '');
    setValueProducts('validityAmount', '');
    setValueProducts('reassignmentQuota', '');
    setValueProducts('deviceType', '');
    setValueProducts('credentialsDeliveryType', '');
    setIsEditing(false);
    setSelectedIndex(null);
    resetProduct();
    setDeviceTypeOptions([]);
    setCredentialsOptions([]);
  };

  const handleCancelEdit = () => {
    setValueProducts('productService', '');
    setValueProducts('quantityToAddService', '');
    setValueProducts('validityService', '');
    setValueProducts('validityAmount', '');
    setValueProducts('reassignmentQuota', '');
    setValueProducts('deviceType', '');
    setValueProducts('credentialsDeliveryType', '');
    setIsEditing(false);
    setSelectedIndex(null);
    resetProduct();
    setDeviceTypeOptions([]);
    setCredentialsOptions([]);
  };

  const handleEditProduct = (index) => {
    const product = servicesToAdd[index];

    setValueProducts('productService', product.productService);
    setValueProducts('quantityToAddService', product.quantityToAddService);
    setValueProducts('validityService', product.validityService);
    setValueProducts('validityAmount', product.validityAmount);
    setValueProducts('reassignmentQuota', product.reassignmentQuota);
    if (productType.length > 0) {
      const deviceTypeId = productType.find(
        (pack) => pack.value === product.productService,
      )?.deviceTypeId;
      const deviceTypeIdsArray = deviceTypeId ? deviceTypeId.split(',').map(Number) : [];
      if (deviceTypeIdsArray.length > 0) {
        setDeviceTypeOptions(listDeviceTypeContracts.filter(
          (option) => deviceTypeIdsArray.includes(option.value),
        ));
      }
    }
    setValueProducts('deviceType', product.deviceType);
    if (product.deviceType === 1) {
      setCredentialsOptions(listCredentialDeliveryContracts.filter((option) => option.value === 1));
    } else {
      setCredentialsOptions(listCredentialDeliveryContracts);
    }
    setValueProducts('credentialsDeliveryType', product.credentialsDeliveryType);
    setIsEditing(true);
    setSelectedIndex(index);
    clearErrorsProducts();
  };

  const handleDeleteProduct = (index) => {
    setValueProducts('productService', '');
    setValueProducts('quantityToAddService', '');
    setValueProducts('validityService', '');
    setValueProducts('validityAmount', '');
    setValueProducts('reassignmentQuota', '');
    setValueProducts('deviceType', '');
    setValueProducts('credentialsDeliveryType', '');
    setIsEditing(false);
    setSelectedIndex(null);
    resetProduct();
    setServicesToAdd((prevServices) => prevServices.filter((_, i) => i !== index));
    setDeviceTypeOptions([]);
    setCredentialsOptions([]);
  };

  return (
    <>
      <Grid item xs={12} style={{ paddingLeft: '0px' }}>
        <Typography align="left" className={classes.titleForm} variant="h6">
          Servicios Contratados
        </Typography>
      </Grid>
      <Container component="main" maxWidth="lg">
        <Paper elevation={3} className={classes.paperGroup}>
          <form autoComplete="off">
            <Grid
              container
              spacing={4}
              style={{ paddingLeft: '60px', paddingRight: '60px' }}
            >
              <Grid item xs={12} md={8}>
                <InputLabel />
                <CustomSelect
                  label="Servicios"
                  name="productService"
                  options={productType}
                  control={controlProducts}
                  rule={Rules.required}
                  required
                  error={errorsProducts}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{ onChange: (e) => setProductType(e) }}
                  classes={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                  label="Cantidad"
                  name="quantityToAddService"
                  control={controlProducts}
                  error={errorsProducts}
                  required
                  rule={Rules.numerico}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{
                    maxLength: 9,
                  }}
                  onValidate={(e) => {
                    if (e.target.value <= 0) {
                      setValueProducts('quantityToAddService', '');
                    } else if (e.target.value > 100000000) {
                      setValueProducts(
                        'quantityToAddService',
                        lastValidValueQuantityProducts,
                      );
                    } else {
                      setLastValidValueQuantityProducts(e.target.value);
                    }
                  }}
                  classes={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputLabel />
                <CustomSelect
                  label="Tipo vigencia"
                  name="validityService"
                  options={listValidityTypeContracts}
                  control={controlProducts}
                  required
                  rule={Rules.required}
                  error={errorsProducts}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{ onChange: (e) => setValidityType(e) }}
                  classes={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                  label="Cantidad vigencia"
                  name="validityAmount"
                  control={controlProducts}
                  error={errorsProducts}
                  required
                  rule={Rules.numerico}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{
                    maxLength: 5,
                  }}
                  onValidate={(e) => {
                    if (e.target.value <= 0) {
                      setValueProducts('validityAmount', '');
                    }
                    const valueTypeValidity = getValuesProducts('validityService');
                    if (transformedListMaxValidityAmountContracts.length > 0) {
                      const valueMax = transformedListMaxValidityAmountContracts.find(
                        (maxValidity) => maxValidity.value === valueTypeValidity,
                      )?.amount;
                      if (e.target.value > valueMax) {
                        setValueProducts('validityAmount', '');
                      }
                    }
                  }}
                  classes={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomInput
                  label="Cupo Reasignación"
                  name="reassignmentQuota"
                  control={controlProducts}
                  error={errorsProducts}
                  required
                  rule={Rules.numerico}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{
                    maxLength: 5,
                  }}
                  onValidate={(e) => {
                    if (e.target.value.startsWith('0')) {
                      setValueProducts('reassignmentQuota', '0');
                    }
                    if (
                      e.target.value < 0 || e.target.value > parameterMaxReassignmentQuota
                    ) {
                      setValueProducts('reassignmentQuota', '');
                    }
                  }}
                  classes={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel />
                <CustomSelect
                  label="Tipo de dispositivo"
                  name="deviceType"
                  options={deviceTypeOptions}
                  control={controlProducts}
                  rule={Rules.required}
                  required
                  error={errorsProducts}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{ onChange: (e) => setDeviceType(e) }}
                  classes={classes.typeInput}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel />
                <CustomSelect
                  label="Tipo entrega credenciales"
                  name="credentialsDeliveryType"
                  options={credentialsOptions}
                  control={controlProducts}
                  rule={Rules.required}
                  required
                  error={errorsProducts}
                  styleFromHelperText={{ style: { marginRight: 10 } }}
                  inputProps={{ onChange: (e) => setCredentialsType(e) }}
                  classes={classes.typeInput}
                />
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Box className={classes.submit}>
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    size="medium"
                    className={classes.btnBlueSubmit}
                    onClick={handleSubmitProducts(handleAgregarProducto)}
                  >
                    {isEditing ? 'Actualizar servicio' : 'Guardar servicio'}
                  </Button>
                  {isEditing && (
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      size="medium"
                      className={classes.btnBlueSubmit}
                      onClick={handleCancelEdit}
                    >
                      Cancelar edición
                    </Button>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                {servicesToAdd && servicesToAdd.length > 0 ? (
                  <Box style={{ minWidth: '500px' }}>
                    <TableContainer
                      component={Paper}
                      style={{ borderRadius: '12px' }}
                      className={classes.scrollBar}
                    >
                      <Table style={{ minWidth: 300, whiteSpace: 'nowrap' }}>
                        <TableHead className={classes.tableHead}>
                          <TableRow>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              Servicio
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              Cantidad
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              Tipo de Vigencia
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              Cantidad de Vigencia
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              Cupo Reasignación
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              Tipo dispositivo
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            >
                              Tipo entrega
                            </TableCell>
                            <TableCell
                              align="center"
                              className={classes.tableCell}
                            />
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {servicesToAdd.map((service, index) => (
                            <TableRow key={index}>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  color: '#333',
                                  borderBottomColor:
                                    index === servicesToAdd.length - 1
                                      ? 'transparent'
                                      : '#009BDD',
                                }}
                              >
                                {productType.find(
                                  (product) => product.value === service.productService,
                                )?.label || 'Desconocido'}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  color: '#333',
                                  borderBottomColor:
                                    index === servicesToAdd.length - 1
                                      ? 'transparent'
                                      : '#009BDD',
                                }}
                              >
                                {service.quantityToAddService}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  color: '#333',
                                  borderBottomColor:
                                    index === servicesToAdd.length - 1
                                      ? 'transparent'
                                      : '#009BDD',
                                }}
                              >
                                {listValidityTypeContracts.find(
                                  (validity) => validity.value === service.validityService,
                                )?.label || 'Desconocido'}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  color: '#333',
                                  borderBottomColor:
                                    index === servicesToAdd.length - 1
                                      ? 'transparent'
                                      : '#009BDD',
                                }}
                              >
                                {service.validityAmount}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  color: '#333',
                                  borderBottomColor:
                                    index === servicesToAdd.length - 1
                                      ? 'transparent'
                                      : '#009BDD',
                                }}
                              >
                                {service.reassignmentQuota}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  color: '#333',
                                  borderBottomColor:
                                    index === servicesToAdd.length - 1
                                      ? 'transparent'
                                      : '#009BDD',
                                }}
                              >
                                {listDeviceTypeContracts.find(
                                  (validity) => validity.value === service.deviceType,
                                )?.label || 'Desconocido'}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  color: '#333',
                                  borderBottomColor:
                                    index === servicesToAdd.length - 1
                                      ? 'transparent'
                                      : '#009BDD',
                                }}
                              >
                                {listCredentialDeliveryContracts.find(
                                  (validity) => validity.value === service.credentialsDeliveryType,
                                )?.label || 'Desconocido'}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  color: '#333',
                                  borderBottomColor:
                                    index === servicesToAdd.length - 1
                                      ? 'transparent'
                                      : '#009BDD',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <IconButton
                                    onClick={() => handleEditProduct(index)}
                                    style={{
                                      fontSize: '24px',
                                      marginRight: '10px',
                                      color:
                                        selectedIndex === index
                                          ? 'rgba(0, 0, 0, 0.26)'
                                          : theme.palette.primary.main,
                                    }}
                                    disabled={selectedIndex === index}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => handleDeleteProduct(index)}
                                    style={{
                                      fontSize: '24px',
                                      marginLeft: '10px',
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                ) : (
                  <Box style={{ fontSize: '15px', fontWeight: 'bold' }}>
                    <p>No hay servicios agregados aún.</p>
                  </Box>
                )}
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default CertificationServices;
