import React from 'react';

const SuccesfullIcon = (props) => {
  const { color } = props;
  return (
    <svg
      width="84"
      height="85"
      viewBox="0 0 84 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42 84.5C65.196 84.5 84 65.696 84 42.5C84 19.304 65.196 0.5 42 0.5C18.804 0.5 0 19.304 0 42.5C0 65.696 18.804 84.5 42 84.5Z"
        fill="white"
      />
      <path
        d="M42 83.5C64.6437 83.5 83 65.1437 83 42.5C83 19.8563 64.6437 1.5 42 1.5C19.3563 1.5 1 19.8563 1 42.5C1 65.1437 19.3563 83.5 42 83.5Z"
        stroke="#3F3D56"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.5959 56.1396C40.819 57.1491 39.6173 57.7405 38.3438 57.7405L38.3249 57.7404C37.4435 57.7404 36.5859 57.4547 35.8806 56.926L26.6035 49.8238C24.8052 48.4459 24.4643 45.8711 25.8422 44.0727C27.2201 42.2744 29.7949 41.9334 31.5934 43.3114L37.5739 47.8972L51.707 29.4657C53.0855 27.6677 55.6606 27.3279 57.4584 28.7064L57.3705 28.8257L57.4606 28.7082C59.2561 30.0881 59.5957 32.661 58.2198 34.4596L41.5959 56.1396Z"
        fill={color}
      />
    </svg>
  );
};

export default SuccesfullIcon;
