import React from 'react';

const WarningIcon = (props) => {
  const { color } = props;
  return (
    <svg
      id="Componente_192_133"
      data-name="Componente 192 – 133"
      xmlns="http://www.w3.org/2000/svg"
      width="95.297"
      height="86.001"
      viewBox="0 0 95.297 86.001"
    >
      <g
        id="Grupo_3274"
        data-name="Grupo 3274"
        transform="translate(10.648 -20.648)"
      >
        <g
          id="Elipse_162"
          data-name="Elipse 162"
          transform="translate(0.649 20.648)"
          fill="#fff"
          stroke="#3f3d56"
          strokeWidth="2"
        >
          <circle cx="42" cy="42" r="42" stroke="none" />
          <circle cx="42" cy="42" r="41" fill="none" />
        </g>
        <circle
          id="Elipse_168"
          data-name="Elipse 168"
          cx="42"
          cy="42"
          r="42"
          transform="translate(-10.648 22.649)"
          fill="rgba(0,0,0,0.07)"
        />
      </g>
      <g
        id="Grupo_3283"
        data-name="Grupo 3283"
        transform="translate(53.143 10.753) rotate(15)"
      >
        <rect
          id="Rectángulo_1450"
          data-name="Rectángulo 1450"
          width="11.819"
          height="36.285"
          rx="5.909"
          transform="translate(0.834 0)"
          fill={color}
        />
        <circle
          id="Elipse_176"
          data-name="Elipse 176"
          cx="6.894"
          cy="6.894"
          r="6.894"
          transform="translate(-0.015 41.268)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default WarningIcon;
